import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { isPlatformBrowser } from "@angular/common";

@Injectable({ providedIn: 'root' })

export class PersonaService {

    private url: string
    private cmsUrl: string
    loged: boolean
    private $userLogued = new BehaviorSubject<any>(false) //subject se encarga de producir el flujo de eventos
    public userIsLoged = this.$userLogued.asObservable()

    constructor(private _http: HttpClient,
        private router: Router,
        @Inject(PLATFORM_ID) private platformId) {
        this.url = environment.apiUrl + '/customers/'
        this.cmsUrl = environment.apiNews
    }

    notificarLoginStatus() {
        let user: any;
        if(isPlatformBrowser(this.platformId)){
            //console.log(localStorage.getItem('user'))
            if (localStorage.getItem("user") != null) {
                user = JSON.parse(localStorage.getItem("user"))
            }
            if (sessionStorage.getItem("user") != null) {
                user = JSON.parse(sessionStorage.getItem("user"))
            }
        }else{
            user = null
        }
        (user != null && user.is_valid) ? this.loged = true : this.loged = false;
        this.$userLogued.next(this.loged)
    }

    isLogged() {
        return this.loged
    }

    logOut() {
        localStorage.clear();
        sessionStorage.clear();
        this.notificarLoginStatus()
        this.router.navigate(['/'])
    }

    logIn(user, remember) {
        if (remember) {
            localStorage.setItem("user", JSON.stringify(user));
        } else {
            sessionStorage.setItem("user", JSON.stringify(user));
        }
        this.notificarLoginStatus();
    }

    postUser(user) {
        let userData = [{
            "email": user.email,
            "first_name": user.firstName,
            "last_name": user.lastName,
            "authentication": {
                "force_password_reset": false,
                "new_password": user.password
            }
        }]
        return this._http.post(this.url, userData, { observe: 'response' })
    }

    getUser() {
        let id
        if (localStorage.getItem('user')) {
            id = JSON.parse(localStorage.getItem('user')).customer_id
        } else {
            id = JSON.parse(sessionStorage.getItem('user')).customer_id
        }
        return this._http.get(this.url + `id/${id}`, { observe: 'response' })
    }

    updateUser(id, user) {
        let userData = [{
            "id": id,
            "email": user.email,
            "first_name": user.firstName,
            "last_name": user.lastName,
            "authentication": {
                "force_password_reset": true,
                "new_password": user.password
            }
        }]
        return this._http.put(this.url, userData, { observe: 'response' })
    }


    postLogin(email, password) {
        let loginData = {
            "email": email,
            "password": password,
            "channel_id": 1
        }
        //console.log(loginData)
        return this._http.post(this.url + "login", loginData, { observe: 'response' })
    }

    subscribeUser(user) {
        let subscriberData = {
            "email": user.email,
            "first_name": user.firstName,
            "last_name": user.lastName,
            "source": "Create Account"
        }
        return this._http.post(this.url + "subscribers", subscriberData, { observe: 'response' })
    }
    // Subscribe User, pero desde el popup
    subscribeUserPopUp(name, email) {
        let subscriberData = {
            "email": email,
            "first_name": name,
            "source": "PopUp Subscribe"
        }
        return this._http.post(this.url + "subscribers", subscriberData, { observe: 'response' })
    }

    subscribeUserCms(name,email){
        let subscriberCms = {
            "data":{
                "email":email,
                "name":name
            }
        }
        return this._http.post(this.cmsUrl + "/newsletters", subscriberCms, { observe: 'response' })
    }

    getUserLocalData() {
        let user = { is_valid: false, customer_id: 0 }
        if (localStorage.getItem('user')) {
            user = JSON.parse(localStorage.getItem('user'))
        }
        if (sessionStorage.getItem('user')) {
            user = JSON.parse(sessionStorage.getItem('user'))
        }
        return user
    }


}